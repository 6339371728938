@import "assets/variables";
@import "assets/primeng-globals";


/*-------------------NORMALIZING-------------------*/
html {
  height: 100%;

  body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    color: #000;
    background-color: $backgroundWhite;
    height: 100%;

    // hide scroll bar edge
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

.item-right {
  display: flex;
  justify-content: flex-end;
}

.library-link {
  cursor: pointer;
  text-decoration: underline;
}

.data-row {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}

// hide scrollbar globally
::-webkit-scrollbar {
  display: none;
}

::ng-deep .p-dialog .p-dialog-content {
  padding: 0.6rem;
}

h3 {
  font-size: 1.1rem;
}

// global used style classes
.plate-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  margin-top: 1.5rem;
}

.plate-container_48_percent {
  width: 48%;
}

.plate-detail-container {
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
