// Colors
$redPrimary: #B70E0C;
$greenPrimary: #189d18;
$dark-blue: #ecf0f3;

// Background Colors
$backgroundWhite: white;
$backgroundMenuWhite: white;
$backgroundRed: $redPrimary;
$backgroundGreen: $greenPrimary;
$backgroundGray: #e5e3e3;
$backGroundCheckBox: #c0c0c0;
$backgroundDialogHeader: #B4B4B4FF;
$backgroundWarning: #dc6405e6;


// Text Colors
$textWhite: #fff;
$pendingText: #C0BFBD;
$menuItemHoover: #fafafa;
$errorText: #E84855;
$positiveText: #189d18;


// Dimension
$headerHeight: 60px;
$workflow-dialog-step-menu-height: 4.5rem;
$pageMargin: 1rem;

