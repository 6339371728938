@import "./variables";
@import "primeng/resources/primeng.css";

//------ Checkbox---------
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $backGroundCheckBox !important;
  background: $backGroundCheckBox !important;
}

p-checkbox-box.p-highlight {
  border-color: $backGroundCheckBox !important;
  background: $backGroundCheckBox !important;
}

.p-checkbox-box.p-focus {
  box-shadow: none !important;
  border-color: $backGroundCheckBox !important;
}

.p-checkbox-label {
  font-size: .875rem;
}

// ----- Confirm Dialog -------
p-confirmDialog .p-dialog {

  .p-dialog-header {
    border-bottom: none;
    color: $textWhite;
    background-color: $backgroundDialogHeader;
    font-size: 1rem;

    .p-dialog-header-icon {
      color: $textWhite;
    }
  }

  .p-confirm-dialog-message {
    white-space: pre-line;
  }

  .p-dialog-footer button:nth-child(2).p-button {
    background-color: $greenPrimary;
    border-color: $greenPrimary;

    &:focus {
      box-shadow: none;
    }
  }

}

//----- Dialog -----
p-dialog .p-dialog {
  .p-dialog-header {
    background-color: $backgroundDialogHeader;
    color: $textWhite;
    font-size: 1rem;
    padding-block: .75rem;

    .p-dialog-header-icon {
      color: $textWhite;
    }
  }

  .p-dialog-content {
    padding: 0 1rem 1rem 1rem;
  }
}

// ---- Date Picker ------
p-calendar {
  .p-calendar {
    height: 100%;
  }
}

// ---- Autocomplete ---
p-autoComplete {

  .p-autocomplete-dropdown {
    border-color: #607D8B;
    background-color: #607D8B;

    height: 30px !important;

    &:hover {
      background: #546E7A !important;
      border-color: #546E7A !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem #607D8B;
    }
  }

  .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem #C0BFBD;
    border-color: #546E7A !important;
  }
}

// ---- Dropdown ----
p-dropdown {
  .p-dropdown.p-component.p-focus {
    box-shadow: 0 0 0 0.2rem #C0BFBD;
    border-color: #546E7A !important;
  }
}

// ---- Calendar ----
p-calendar {
  .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem #C0BFBD;
    border-color: #546E7A !important;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #546E7A !important;
  background: #546E7A !important;
}

.p-inputswitch {

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $greenPrimary !important;
      &:before {
        background: #ffffff !important;
      }
    }
  }

  .p-inputswitch-slider {
    background: $redPrimary !important;
  }

}

